import { graphql, useStaticQuery } from 'gatsby';

const UseEvents = () => {
  const data = useStaticQuery(graphql`
    query NewHiresPageQuery {
      allMedcoeEventAncmtJson(filter: { Portlet_ID: { eq: 20 } }) {
        nodes {
          PublicEvent_ID
          Event
          Description
        }
      }
    }
  `);

  return data.allMedcoeEventAncmtJson.nodes.map(event => ({
    id: event.PublicEvent_ID,
    question: event.Event,
    answer: event.Description
  }));
};

export default UseEvents;
