import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import SEO from '../components/seo';
import useNewHires from '../hooks/use-newhires';
import Layout from '../components/layout';
import { parseNEWHIRES } from '../components/utitlies/utility';

//const yellowHeading = css`
  //background: white;
  //line-height: 44px;
  //margin: 0;
  //padding-left: 10px;
  //padding-top: 10px;
  //textAlign: 'center';
//`;

const accordionButton = css`
  margin: 3px 0;
  &:active,
  &:focus {
    outline: dotted 2px !important;
    outline-offset: 2px !important;
  }
`;

const eventContact = css`
  margin-top: 50px;
`;

export default function NewHires() {
  const allFaqs = useNewHires();
  return (
    <Layout>
      <SEO title='New Arrivals (Hires)' />
      <div className='grid-container'>
        <h2 style={{ textAlign: 'center'}}>Welcome to MEDCoE Onboarding</h2>
        <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} title="MEDCOE group22.jpg" src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/NewArrivals.PNG" alt="" width="350" height="350" />
        <p style={{ textAlign: 'center'}}><a href="https://youtu.be/vmI4SJwkrrk?si=KvTla2-3Sp9uSmtw" target="_blank" rel="noopener">Welcome Video</a></p>
      <h3 style={{ textAlign: 'center'}}>Welcome New Members to the Wonderful World of MEDCoE</h3>
      <p>MEDCoE develops medical professionals, integrates medical capabilities, and drives change in the Army Medicine to enable the Joint Forces to win the Nations’ wars. We have the largest Civilian-Accredited Service School, home of the Combat Medic, and second largest Active Duty MOS in the Army. The Army Proponent for Envisioning and Designing the Medical Capabilities for the Next War. Delivers professional health care services education and training to 21 MOSs, 16 AOCs, & 24 Degree Programs for Officer and Enlisted personnel.</p>
<p>We’re excited to have you as part of the MEDCoE team. As a new team member, you’ll go through the Onboarding process. Onboarding is a vital process in which Civilians and Military members are integrated into the organization. They will be well-equipped with the knowledge, and direct personal assistances to help prepare them to be fully engaged to become a productive member of the MEDCoE team. Our Onboarding Program will promote a better understanding of the culture, mission and goals of our organization. The specific components of onboarding will vary for military, civilian, and contractor personnel. Please review your role below that applies to you.</p>
      <h3 style={{ textAlign: 'center'}}>New Civilians</h3>
      <p >You are required to attend the 10-day mandatory onboarding program. Once you receive your Final Job Offer (FJO) with your specified Entrance on Duty (EOD) you will be contacted by the Onboarding Program Manager, your Supervisor/Sponsor 5-7 days prior to your start date with detailed reporting instructions.</p>
    <h3 style={{ textAlign: 'center'}}>Permanent Party Military</h3>
    <p >Once you complete the Installation in-processing at the Military Personnel Division (MPD), you will be required to attend MEDCoE mandatory newcomers’ orientation, that will coincide with the civilians Onboarding schedule, you are only required to attend days 1-3 and 8-10. Your unit S1 will provide you with the schedule with times and locations.</p>
    <h3 style={{ textAlign: 'center'}}>Contractors</h3>
    <p>Please report directly to your Contracting Officer Representative (COR). They will assist with required in-processing.</p>


        <div className='grid-row'>
          <div className='grid-col-12'>
            <section>
              <div className='usa-accordion'>
                {allFaqs.length > 0 ? (
                  allFaqs.map((newhires) => (
                    <React.Fragment key={newhires.id}>
                      <button
                        id={`btn_${newhires.id}`}
                        className='usa-accordion__button'
                        aria-expanded='false'
                        aria-controls={`ctl_${newhires.id}`}
                        css={accordionButton}
                      >
                        {newhires.question}
                      </button>
                      <div
                        id={`ctl_${newhires.id}`}
                        className='usa-accordion__content'
                        hidden={true}
                        dangerouslySetInnerHTML={{ __html: parseNEWHIRES(newhires.answer) }}
                      ></div>
                    </React.Fragment>
                  ))
                ) : (
                  <p>Sorry no FAQs, please check back later.</p>
                )}
              </div>
            </section>
            <section>
              <div>
                <div css={eventContact}>
                  <h3 style={{ textAlign: 'center'}}>POC</h3>
                  <p style={{ textAlign: 'center'}}>Please send all inquiries about Onboarding to:</p>
                  <p style={{ textAlign: 'center'}}>MEDCoE CHRD G1</p>
                  <p style={{ textAlign: 'center'}}><a href="mailto:usarmy.jbsa.medical-coe.mbx.onboarding@army.mil">usarmy.jbsa.medical-coe.mbx.onboarding@army.mil</a></p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
}
