import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/react';
import Banner from './banner';
import Navigation from './navigation';
import Footer from './footer';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Banner />
      <Navigation siteTitle={data.site.siteMetadata.title} />
      <a className='usa-skipnav' href='#main-content'>
        Skip to main content
      </a>

      <main id='main-content'>
        {/* <div className='grid-container'> */}
        {/*TODO: if aside go here */}
        {/* <div
            className='usa-layout-docs__main desktop:grid-col-9 usa-prose'
            css={css`
              padding: 2rem 0;
              min-height: 400px;
            `}
          > */}
        {children}
        {/* </div> */}
        {/* </div> */}
      </main>

      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
